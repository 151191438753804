.App {
    text-align: center;
    font-family: '微软雅黑', 'Microsoft YaHei';
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40px;
}

.App-header {
    background-color: #222;
    height: 100px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mask {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.image.mask {
    position: relative;
}

.hidden-input input {
    width: 100%;
    height: 100%;
    padding-left: 100000px !important;
}

.before-upload {
    font-size: large;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    z-index: 1;
}

.before-upload label {
    width: 100% !important;
    height: 100% !important;
}

#the-image-wrapper {
    position: relative;
}

#the-image-wrapper .image-mask {
    position: absolute;
    width: 100%;
    border: solid 1px red;
    opacity: 0.2;
    background-color: black;
}

#the-image-wrapper .image-crop {
    position: absolute;
    width: 100px;
    height: 100px;
    display: block;
    border: solid 2px blue;
    overflow: hidden;
    border-radius: 50%;
}

.label-button button[for] {
    padding: 0 !important;
}

.label-button label[for] {
    display: block;
    height: 100%;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}